import React from 'react'
import styled from 'styled-components'

const CardContainer = styled.div`
  width: 75%;
  margin: 5rem auto;
`

const CardGridContainer = (props) => {
  return (
    <CardContainer>
      {props.children}
    </CardContainer>
  )
}

export default CardGridContainer
