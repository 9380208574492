import React from 'react'
import styled from 'styled-components'

const Intro = (props) => {
  const displayProperty = props.open ? "none" : "flex"
  const leftCalc = props.open ? "-100%" : "0%"

  const IntroContainer = styled.div`
    flex-direction: column;
    top: 0;
    left: ${leftCalc};
    transition: all 0.5s ease;
    width: 100vw;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    position: fixed;
    background-color: #1a191d;
    font-family: "Raleway";

    h1, h3 {
      color: white;
      margin: 0;
    }

    h1 {
      margin-top: 2rem;
      margin-bottom: 0.5rem;
    }

    h3 {
      margin-bottom: 2rem;
    }

    img {
      max-width: 120px;
      height: auto;
    }
  `
  return (
    <IntroContainer onClick={props.openApp}>
      <div>
        <img src="/images/image.gif" />
      </div>
      <h1>
        Merry Christmas!
      </h1>
      <h3>
        I found some shirts you might like. Pick one out and I'll order it
        for you.
      </h3>
      <div>
        <img src="/images/hawaii.gif" />
      </div>
    </IntroContainer>
  )
}

export default Intro
